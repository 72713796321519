<template>
  <div>
    <div class="learn" ref="learnHomeRef">
      <div class="learn-img">
        <div class="text-area">
          <h1>经典案例</h1>
          <p>
            零探科技专注3D底层技术研发，平台服务与行业需求深度结合
            基于数字孪生技术，形成一系列可视化产品，成功应用于航空
            航天、智慧园区、智慧工厂、C2M电商、智慧机房、智慧交通
            智慧能源、智慧校园等多个领域
          </p>
        </div>
        <div class="banner"></div>
      </div>
    </div>
    <div class="learn-tab w1120">
      <div
        class="text-item"
        v-for="(item, index) in tabList"
        @click="selectItem(index)"
        :class="index == selectIndex ? `select` : ''"
      >
        {{ item.labelName }}
      </div>
    </div>
    <div class="content w1120">
      <div class="card-content">
        <div
          class="card"
          v-for="(item, index) in getPorjectList"
          @click="toDetil(item.caseId)"
        >
          <div class="play">
            <img src="@/assets/learn/Vector.png" alt="" />
          </div>
          <div class="video-content">
            <img :src="item.casePic" alt="" />
            <div class="img-view"></div>
          </div>
          <div class="bottom">{{ item.caseName }}</div>
        </div>
      </div>
      <el-pagination
        @current-change="pageChange"
        background
        layout="prev, pager, next"
        :total="totalPage"
        :hide-on-single-page="true"
        :page-size="9"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { getAllCaseLabel, getDetialCase } from "@/api";

export default {
  data() {
    return {
      scroll: 0,
      pageIndex: 1,
      projectList: [],
      componentMap: [
        "ProductIntroduce",
        "FastRumen",
        "HelpDoc",
        "QuestionCommon",
      ],
      projectListMap: {},
      tabList: [],
      selectIndex: 0,
    };
  },
  async created() {
    let res = await getAllCaseLabel();
    this.tabList = res.data.data;
    this.setProjectList();
    if (this.$route.query?.select) {
      this.selectIndex = Number(this.$route.query.select);
    }
  },
  watch: {
    "$route.query.select"(newV) {
      this.selectIndex = newV;
    },
    selectIndex() {
      this.setProjectList();
    },
  },
  activated() {
    if (this.$route.query.isHome) {
      this.selectIndex = 0;
    }
  },
  computed: {
    getLabelId() {
      return this.tabList[this.selectIndex].labelId;
    },
    totalPage() {
      return this.projectList.length;
    },

    getPorjectList() {
      let res = [];
      let startIndex = (this.pageIndex - 1) * 9;
      let endIndex = startIndex + 9;
      res = this.projectList.slice(startIndex, endIndex);
      return res;
    },
  },
  methods: {
    toDetil(caseId) {
      this.$router.push({
        path: "detial",
        query: { caseId },
      });
    },
    pageChange(index) {
      this.pageIndex = index;
    },
    selectItem(index) {
      this.selectIndex = index;
    },
    setProjectList() {
      if (this.projectListMap[this.selectIndex]) {
        this.projectList = this.projectListMap[this.selectIndex];
      } else {
        this.getDetialCaseList();
      }
    },
    async getDetialCaseList() {
      let detialRes = await getDetialCase(this.getLabelId);
      this.projectList = detialRes.data.data;
      this.projectListMap[this.selectIndex] = detialRes.data.data;
    },
  },
};
</script>
<style>
.el-pagination {
  text-align: center;
  margin-top: 90px;
}
</style>

<style lang="scss" scoped>
.learn {
  width: 100%;
  height: 928px;
  background-size: cover;
  // background-attachment: fixed;
  background-position: center 0;
  background-image: url("../../../../assets/banner-bg.png");
  background-repeat: no-repeat;
  color: #333439;
  .learn-img {
    display: flex;
    // align-items: center;
    height: 100%;
    width: 1200px;
    margin: 0 auto;
    .text-area {
      margin-top: 331px;
      h1 {
        font-weight: 700;
        font-size: 48px;
      }
      p {
        margin-top: 36px;
        font-weight: 400;
        width: 500px;
        font-size: 18px;
        line-height: 30px;
      }
      .learn-text {
        font-weight: 700;
        font-size: 64px;
        line-height: 56px;
        position: absolute;
        top: 348px;
        left: 514px;
        color: #ffffff;
      }
    }
    .banner {
      width: 666px;
      height: 100%;
      background-size: cover;
      background-image: url("../../../../assets/index-bg.png");
    }
  }
}
.learn-tab {
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #ebebeb;
  .text-item {
    cursor: pointer;
    height: 100%;
    font-weight: 500;
    font-size: 22px;
    margin-right: 50px;
    margin-left: 50px;
    line-height: 60px;
    border-bottom: 2px solid transparent;
    color: #333439;
    &:hover {
      border-bottom: 2px solid #357aff;
      color: #357aff;
    }
  }
  .select {
    border-bottom: 2px solid #357aff;
    color: #357aff;
  }
}
.content {
  padding-top: 70px;
  .card-content {
    display: grid;
    grid-template-columns: 350px 350px 350px;
    column-gap: 35px;
    row-gap: 40px;
    margin-bottom: 110px;
  }
  .card {
    background: #ffffff;
    box-shadow: -16px -16px 40px rgba(255, 255, 255, 0.8),
      16px 4px 64px rgba(18, 61, 101, 0.2);
    border-radius: 12px;
    overflow: hidden;
    position: relative;
    .play {
      cursor: pointer;
      width: 46px;
      height: 46px;
      display: none;
      position: absolute;
      left: 50%;
      top: calc(98px - 23px);
      transform: translate(-50%, 0);
    }
    .video-content {
      width: 100%;
      cursor: pointer;
      height: 196px;
      position: relative;
      .img-view {
        width: 100%;
        height: 196px;
        display: block;
        background: rgba(0, 0, 0, 0.3);
        position: absolute;
        top: 0;
        left: 0;
        // transition: all 0.1s;
        opacity: 0;
      }
    }
    .bottom {
      height: 54px;
      width: 100%;
      padding-left: 25px;
      padding-top: 12px;
      font-weight: 400;
      font-size: 16px;
      color: #333439;
      cursor: pointer;
    }
  }
  .card:hover {
    .play {
      display: block;
      z-index: 2;
    }
    .img-view {
      opacity: 1;
    }
    .bottom {
      color: #357aff;
    }
  }
}
</style>
